<template>
  <b-modal
      id="modal-category-create"
      title="Создание новой категории"
      ok-title="Сохранить"
      @ok="saveCategory"
      cancel-title="Отмена"
      cancel-variant="outline-secondary"
  >
    <div class="form-group">
      <label class="title">Родительская категория</label>
      <TreeSelect
          v-model="category.parent_id"
          :options="categories"
          :searchable="true"
          :normalizer="normalizer"
          placeholder="Выберите родительскую категорию"
      />
    </div>
    <div class="form-group">
      <label class="title">Название *</label>
      <input type="text" class="form-control" v-model="category.name">
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import TreeSelect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  name: 'CategoryCreateModal',
  components: {
    BModal, TreeSelect,
  },
  data() {
    return {
      category: {
        parent_id: null,
        name: null,
        product_option_template_id: null,
      },
      searchQuery: null,
      categories: [],
      templates: [],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          children: node.all_children,
        };
      },
    };
  },
  async mounted() {
    await this.getCategories();
  },
  methods: {
    async getCategories() {
      this.categories = (await this.$api.categories.select()).data;
    },
    async saveCategory() {
      if (!this.category.name) {
        this.$bvToast.toast('Заполните все поля', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
        return false;
      }

      if (this.productOptionTemplate) {
        this.category.product_option_template_id = this.productOptionTemplate.id;
      }

      try {
        await this.$api.categories.create(this.category);
        this.$bvToast.toast('Категория успешно создана', {
          title: 'Успех',
          variant: 'success',
          solid: true,
        });
        await this.getCategories();
        await this.$emit('update');
      } catch (e) {
        this.$bvToast.toast('Ошибка при создании категории', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
      }

      return true;
    },
  },
};
</script>

<style scoped>

</style>
