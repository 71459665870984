<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <app-breadcrumb :breadcrumbs="breadcrumbs"/>
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <div class="card">
            <div class="m-2">
              <div class="row">
                <div class="col col-12 col-md-6 d-flex align-items-center justify-content-start mb-1 mb-md-0">
                  <button
                      v-b-modal.modal-category-create
                      v-if="$can('manage', 'categories')"
                      class="btn btn-primary"
                  >
                    <span class="text-nowrap">Добавить Категорию</span>
                  </button>
                </div>
                <div class="col-12 col-md-6">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-form-input
                        v-model="searchQuery"
                        @change="getCategories" class="d-inline-block mr-1"
                        placeholder="Поиск..."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="mx-2 mb-2">
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ categories.from }} по {{ categories.to }} из {{ categories.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="categories.total"
                      :per-page="categories.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
            <div class="position-relative table-responsive">
              <table class="table b-table">
                <thead>
                <!---->
                <tr>
                  <th>#</th>
                  <th>Название</th>
                  <th>Набор опций</th>
                  <th>Товары</th>
                  <th>Бренды</th>
                  <th>Размеры</th>
                  <th>Путь</th>
                  <th v-if="$can('manage', 'categories')">Действия</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="category in categories.data" :key="category.id">
                  <td>
                    <router-link :to="{name: 'category-details', params: { id: category.id, tab: 'options' }}">
                      {{ category.id }}
                    </router-link>
                  </td>
                  <td>{{ category.name }}</td>
                  <td class="text-center">{{ category.product_option_template ? category.product_option_template.name : '-' }}</td>
                  <td>
                    <router-link :to="{name: 'products', query: { category_id: category.id}}">
                      {{ category.products_count }}
                    </router-link>
                  </td>
                  <td>{{ category.brands_count }}</td>
                  <td>{{ category.sizes_count }}</td>
                  <td>
                    <router-link :to="{ name: 'category-list', params: { id: category.parent_id } }">
                      {{ category.full_parent_path }}
                    </router-link>
                  </td>
                  <td v-if="$can('manage', 'categories')">
                    <button
                        @click="editCategory(category.id)"
                        v-if="$can('manage', 'categories')"
                        class="btn btn-info"
                    >
                      Редактировать
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="mx-2 mb-2">
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ categories.from }} по {{ categories.to }} из {{ categories.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="categories.total"
                      :per-page="categories.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <CategoryCreateModal @update="getCategories"/>
    <CategoryUpdateModal :categoryId="selectedCategoryId" @update="getCategories"/>
  </div>
</template>

<script>
import { BFormInput, BPagination } from 'bootstrap-vue';
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue';
import CategoryCreateModal from '@/views/categories/CategoryCreateModal.vue';
import CategoryUpdateModal from '@/views/categories/CategoryUpdateModal.vue';

export default {
  name: 'CategoryList',
  components: {
    BFormInput, BPagination, AppBreadcrumb, CategoryCreateModal, CategoryUpdateModal,
  },
  data() {
    return {
      currentPage: 1,
      breadcrumbs: [],
      selectedCategoryId: null,
      categories: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
        total: null,
        per_page: 10,
      },
      searchQuery: null,
    };
  },
  watch: {
    '$route.params.id': {
      handler() {
        this.getBreadcrumbs();
        this.getCategories();
      },
      immediate: true,
    },
    async currentPage(newValue) {
      this.categories.current_page = newValue;
      await this.getCategories();
    },
  },
  async mounted() {
    await this.getCategories();
  },
  methods: {
    async getBreadcrumbs() {
      this.breadcrumbs = [{ text: 'Все категории', to: { name: 'category-list' } }];

      if (this.$route.params.id) {
        const breadcrumbs = (await this.$api.categories.path(this.$route.params.id)).data;
        this.breadcrumbs = this.breadcrumbs.concat(breadcrumbs);
      }
    },
    async getCategories() {
      this.categories = (await this.$api.categories.list({
        ...(this.categories.current_page > 1 && { page: this.categories.current_page }),
        limit: 25,
        ...(this.$route.params.id && { parent_id: this.$route.params.id }),
        ...(this.searchQuery && { search: this.searchQuery }),
      })).data;
    },
    editCategory(id) {
      this.selectedCategoryId = id;
      this.$bvModal.show('modal-category-update');
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.amount {
  white-space: nowrap;

  & span.amount {
    font-size: 18px;

  }

  & span.currency {
    font-size: 10px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
